@font-face {
	font-family: "Gilroy";
	src: url("./assets/fonts/Gilroy/Gilroy-Regular.ttf") format("truetype");
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: "Gilroy";
	src: url("./assets/fonts/Gilroy/Gilroy-Medium.ttf") format("truetype");
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: "Gilroy";
	src: url("./assets/fonts/Gilroy/Gilroy-SemiBold.ttf") format("truetype");
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: "Gilroy";
	src: url("./assets/fonts/Gilroy/Gilroy-Bold.ttf") format("truetype");
	font-weight: 700;
	font-style: normal;
}
