@import "./fonts.css";

body {
	margin: 0;
	font-family: "Gilroy", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
		"Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

* {
	font-family: "Gilroy";
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

.preloader {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #fff;

	z-index: 9999;
	transition: opacity 0.3s;
	opacity: 1;
	animation: fadeOut 0.5s linear forwards;
	animation-delay: 3.5s;
}

.loading-bar {
	width: 100%;
	height: 4px;
	animation: loading 3.5s linear forwards;
}

.preloader-text {
	animation: fadeOut 2s linear reverse;
}

@keyframes loading {
	0% {
		width: 0;
	}
	50% {
		width: 50%;
	}
	100% {
		width: 100%;
	}
}

@keyframes fadeOut {
	0% {
		opacity: 1;
	}
	50% {
		opacity: 0.5;
	}
	100% {
		opacity: 0;
	}
}

.opened div, .closed div{
	transition: .3s;
}

.opened div:first-of-type {
	transform: rotate(135deg) translate(2.5px, -2.5px);
}

.opened div:nth-of-type(2) {
	opacity: 0;
}

.opened div:last-of-type {
	transform: rotate(45deg) translate(-2.5px, -2px);
}